import { useMemo } from 'react'
import { serialize, parse } from 'cookie'

import { Router } from '~/i18n'

const TOKEN = 'TOKEN'
const RETURN_PATH = 'RETURN_PATH'
const CURRENT_USER = 'CURRENT_USER'
const MAX_AGE = 10 * 365 * 24 * 60 * 60

export const DEFAULT_REDIRECT = '/organizations/menlo-park'
export const LOGIN_PATH = '/users/login'
export const RESET_PASSWORD_PATH = '/users/reset-password'

export const roles = {
  superadmin: 'HIAGO_SUPERADMIN',
  administrator: 'HIAGO_ADMINISTRATOR',
  user: 'HIAGO_USER',
  guest: 'HIAGO_GUEST',
}

export const isLoggedIn = (ctx) => !!getToken(ctx)

export const redirectBack = () => {
  Router.push(getReturnPath())
  clearReturnPath()
}

export const logOut = () => {
  clearToken()
  clearCurrentUser()
  Router.push(DEFAULT_REDIRECT)
}

const storeReturnPath = (path) => sessionStorage.setItem(RETURN_PATH, path)

const getReturnPath = () => sessionStorage.getItem(RETURN_PATH) || DEFAULT_REDIRECT

const clearReturnPath = () => sessionStorage.removeItem(RETURN_PATH)

const serializeCookie = (key, value) => {
  return serialize(key, value, {
    maxAge: MAX_AGE,
    // a far future's date is setted in order to non expire date
    expires: new Date(Date.now() + MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'strict',
  })
}

const parseCookies = (ctx = null) => {
  // for CLIENT we do need to parse cookies
  if (process.browser) {
    const { cookie } = document
    return parse(cookie || '')
  }

  // for API Routes we don't need to parse the cookies.
  if (ctx?.req?.cookies) {
    return ctx.req.cookies
  }

  // for pages we do need to parse cookies.
  const cookie = ctx?.req?.headers?.cookie
  return parse(cookie || '')
}

export const storeToken = (token) => {
  const cookie = serializeCookie(TOKEN, token)

  // for now it sets only on client side until required ssr
  document.cookie = cookie
}

export const getToken = (ctx = null) => {
  const cookies = parseCookies(ctx)
  return cookies[TOKEN]
}

export const clearToken = () => {
  const cookie = serialize(TOKEN, '', {
    maxAge: -1,
    path: '/',
  })

  // for now it sets only on client side until required ssr
  document.cookie = cookie
}

export const storeCurrentUser = (user) => {
  const cookie = serializeCookie(CURRENT_USER, JSON.stringify(user))

  // for now it sets only on client side until required ssr
  document.cookie = cookie
}

export const getCurrentUser = (ctx = null) => {
  const cookies = parseCookies(ctx)
  return cookies[CURRENT_USER] ? JSON.parse(cookies[CURRENT_USER]) : undefined
}

const clearCurrentUser = () => {
  const cookie = serialize(CURRENT_USER, '', {
    maxAge: -1,
    path: '/',
  })

  // for now it sets only on client side until required ssr
  document.cookie = cookie
}

export const currentUserHasRoles = (...allowedRoles) => {
  const user = getCurrentUser()
  if (!user) return allowedRoles.includes(roles.guest)
  const hasRole = user.userCredentials.nodes
    .some((credential) => allowedRoles.includes(credential.role))

  return hasRole
}

export const currentUserHasVerifiedEmail = () => {
  const user = getCurrentUser()
  const hasVerified = user?.userCredentials?.nodes[0]?.emailVerified

  return hasVerified
}

export const loginRequired = (role = null, skipEmailVerification = false) => (BaseComponent) => (props) => {
  if (
    !isLoggedIn() ||
    !(role && currentUserHasRoles(role)) ||
    (!skipEmailVerification && role === roles.user && !currentUserHasVerifiedEmail())
  ) {
    if (process.browser) {
      storeReturnPath(Router.route)
      Router.push(LOGIN_PATH)
    }
    return null
  }
  return <BaseComponent {...props} />
}

export const useCurrentUser = () => useMemo(getCurrentUser)
